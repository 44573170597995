@tailwind base;
@tailwind components;
@tailwind utilities;

/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');

:root {
  --primary-color: #008083;
}
body {
  font-family: 'Urbanist', sans-serif;
  overflow-x: clip;
}
h2 {
  font-size: 44px;
  font-weight: 600;
  color: white;
}
h5 {
  font-size: 34px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.39);
}
p {
  font-weight: 600;
  color: black;
}
.bg-skoolie-green {
  background: var(--primary-color);
}
.skoolie-green {
  color: var(--primary-color);
}

/* FAQ Dropdown Styles */
.faq-dropdown svg {
  transform: scale3d(1, 1, 1);
  transition: transform .3s;
}
.faq-dropdown.open {
  height: auto;
}
.faq-dropdown.open svg {
  transform: scale3d(1, -1, 1);
}